/* Universal javascript */
import '/src/css/app.css';
import Alpine from 'alpinejs'
import jsResizeAnimationStopper from './utils/resizeAnimationStopper.js'
import swiper from './components/swiper.js'
import localeEn from 'air-datepicker/locale/en';

// Get any third-party libraries
import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';

// Import Plyr
// import Plyr from 'plyr';
// import 'plyr/dist/plyr.css';

const lazySizesConfig = lazySizes.cfg;
lazySizesConfig.init = false;
lazySizes.init();
jsResizeAnimationStopper.init();
swiper.init();

import AirDatepicker from 'air-datepicker';
import 'air-datepicker/air-datepicker.css';

window.Alpine = Alpine;
// window.Plyr = Plyr;


// htmx
if (typeof htmx !== 'undefined') {
    // scroll to top of page after htmx swap
    htmx.on('htmx:afterSwap', function(event) {
        window.scrollTo({top: 0, behavior: 'smooth'});
    });
}
window.datePicker = {
    init(element) {
        new AirDatepicker(element, {
            range: true,
            multipleDatesSeparator: ' - ',
            autoClose: true,
            locale: localeEn,
            buttons: ['clear'],
            onSelect: function(event) {
                if (event.date.length === 2) {
                    console.log('Selected date range: ', event.date[0], event.date[1]);

                    // simulated a field change event to trigger htmx form submission
                    element.dispatchEvent(new Event('change', { bubbles: true }));


                } else {
                    console.log('First date selected: ', event.date[0]);

                    if (typeof event.date[0] === 'undefined') {
                        // this means "clear" the date input field
                        // simulated a field change event to trigger htmx form submission
                        element.dispatchEvent(new Event('change', { bubbles: true }));
                        event.datepicker.hide()
                    }
                }
            }
        });
    }
}

Alpine.start()